<template>
	<!-- v-if="_usrFlagsSome(permissions.readPlayers)" -->
	<v-container
		id="Groups"
		fluid
		tag="section">
		<v-dialog
			@click:outside="exportClose"
			@keydown.esc="exportClose"
			max-width="500px"
			v-model="exportDialog">
			<ExportDialog
				@close="exportClose"
				builder="object"
				delimiter=";"
				:data="auditExportData"
				:filename="'testport'"
				ref="auditExport"></ExportDialog>
		</v-dialog>

		<v-dialog
			class="my-2 fakin-dialog"
			:width="1200"
			:max-width="1200"
			v-model="detailDialog">
			<KeyValueCard
				:item="detail"
				:cols="rows"
				:title="'Audit Detail'"
				:loading="false"
				color="secondary">
				<template #[`item.url.value`]="{ item }">
					<code style="font-size: 0.9rem">{{ item.url }}</code>
				</template>
				<template #[`item.timestamp.value`]="{ item }">
					{{ item.timestamp | Date }}
				</template>
				<template #[`item.input_pl.value`]="{ item }">
					<span class="subtitle-2">Request</span>
					<pre
						style="position: relative"
						:key="`${item.url}-${item.timestamp}-input`"><v-icon @click="copy(item.input_pl)" size="18" :style="`position: absolute; top: ${reqHeight > 45 ? '10px': '2px'}; right: 10px; z-index: 20`">mdi-content-copy</v-icon><code :style="`max-height: max(calc(25vh + 25vh - ${resHeight}px) , 25vh)`" ref="req" id="request">{{ item.input_pl }}</code></pre>
				</template>
				<template #[`item.output_pl.value`]="{ item }">
					<span class="subtitle-2">Response</span>
					<pre
						style="position: relative"
						:key="`${item.url}-${item.timestamp}-output`"><v-icon @click="copy(item.output_pl)" size="18" :style="`position: absolute; top: ${resHeight > 45 ? '10px': '2px'}; right: 10px; z-index: 20`">mdi-content-copy</v-icon><code :style="`max-height: max(calc(25vh + 25vh - ${reqHeight}px) , 25vh)`" ref="resp" id="response">{{ item.output_pl }}</code></pre>
				</template>
				<template #[`item.response_code.value`]="{ item }">
					<span>{{ item.response_code }}</span>
					<v-btn
						:color="item.response_code >= 400 ? 'error' : 'success'"
						width="18"
						height="18"
						fab
						class="elevation-0 ml-2"></v-btn>
				</template>
				<template #[`item.user.value`]="{ item }">
					<span>{{ getUser(item.user) }}</span>
					<v-icon
						v-if="userExist(item.user)"
						size="18"
						color="primary"
						class="ml-2"
						@click.stop.prevent="getUserLink(item.user)">
						mdi-open-in-new
					</v-icon>
				</template>
			</KeyValueCard>
		</v-dialog>

		<v-card class="v-card--material true pa-3 px-5 py-3">
			<CardHeading :title="'Audit Log'">
				<template #append>
					<v-btn
						elevation="1"
						fab
						small
						color="primary"
						@click="onExportOpen">
						<v-icon>mdi-arrow-down-bold-circle</v-icon>
					</v-btn>
				</template>
			</CardHeading>
			<v-data-table
				must-sort
				:sort-desc="true"
				:headers="headers"
				:items="list"
				:item-class="itemClass"
				:options.sync="options"
				@click:row="showDetail"
				:server-items-length="total_records"
				:loading="loading"
				:footer-props="footerProps"
				class="elevation-1">
				<template #top="{ pagination }">
					<div class="d-flex align-center justify-space-between">
						<v-text-field
							class="mr-3 col-3 pb-0"
							dense
							clearable
							:dark="$vuetify.theme.dark"
							:light="!$vuetify.theme.dark"
							@click:clear="onFilter"
							@keydown.enter="onFilter"
							v-model="auditFilter.url_part"
							@input="textFieldSanitize"
							hide-details
							label="Search"></v-text-field>
						<v-data-footer
							class="anton"
							:options.sync="options"
							v-bind="footerProps"
							:pagination="pagination"></v-data-footer>
					</div>
				</template>
				<template #[`item.timestamp`]="{ item }">
					{{ item.timestamp | Date }}
				</template>
				<template #[`item.response_code`]="{ item }">
					{{ item.response_code }}
					<v-btn
						:color="item.response_code >= 400 ? 'error' : 'success'"
						width="18"
						height="18"
						fab
						class="elevation-0 ml-2"></v-btn>
				</template>
				<template #[`item.url`]="{ item }">{{ getUrlPath(item.url) }}</template>
				<template #[`item.user`]="{ item }">
					<v-hover>
						<template #default="{ hover }">
							<v-icon
								v-if="userExist(item.user)"
								size="20"
								:color="hover ? 'kajot-text' : 'primary'"
								class="mr-1"
								@click.stop.prevent="getUserLink(item.user)">
								mdi-open-in-new
							</v-icon>
						</template>
					</v-hover>
					<v-hover>
						<template #default="{ hover }">
							<v-icon
								v-if="userExist(item.user)"
								size="18"
								:color="hover ? 'kajot-text' : 'primary'"
								class="mr-1"
								@click.stop.prevent="copyUserId(item.user)">
								mdi-content-copy
							</v-icon>
						</template>
					</v-hover>
					{{ getUser(item.user) }}
				</template>
			</v-data-table>
		</v-card>
		<FilterSideBar
			refreshButton
			@clear="clearFilter"
			@refresh="onFilter(true)"
			:filterIsEmpty="filterIsEmpty"
			:loading="loading"
			width="400px">
			<AuditForm
				ref="form"
				@apply="onFilter"
				:loading="loading"
				:filterIsEmpty="filterIsEmpty"
				v-model="auditFilter"></AuditForm>
		</FilterSideBar>
		<v-snackbar
			style="position: fixed; bottom: 20px; text-align: center"
			app
			color="menu_background"
			transition="slide-y-reverse-transition"
			:timeout="timeout"
			content-class="kajot-text--text"
			v-model="copied">
			<div class="align-center">
				<v-icon color="info">mdi-information</v-icon>
				{{ copiedText }} copied to clipboard
			</div>
			<template #action>
				<v-btn
					fab
					text
					x-small
					@click="copied = false">
					<v-icon
						color="kajot-text"
						small>
						mdi-close
					</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</v-container>
</template>

<script>
import CardHeading from "../../components/shared/CardHeading.vue";
import FilterSideBar from "../../components/shared/FilterSideBar";
import AuditForm from "../../components/audit/AuditForm";
import ExportDialog from "../../components/shared/ExportDialog.vue";
import KeyValueCard from "../../components/shared/keyValueCard2.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import _ from "lodash";
import hljs from "highlight.js/lib/core";
import table from "../../mixins/table";
import permissions from "../../mixins/permissions";

export default {
	components: {
		CardHeading,
		FilterSideBar,
		AuditForm,
		ExportDialog,
		KeyValueCard,
	},
	data() {
		return {
			copiedText: "",
			copied: false,
			timeout: 1000,
			reqHeight: 0,
			resHeight: 0,
			rowIsClickable: true,
			auditFilter: {
				ts_from: undefined,
				ts_to: undefined,
				user_id: undefined,
				url_part: undefined,
			},
			emptyFilter: {
				ts_from: undefined,
				ts_to: undefined,
				user_id: undefined,
				url_part: undefined,
			},
			detail: undefined,
			detailDialog: false,
			exportDialog: false,
			auditHeaders: {},
			auditData: {},
			rows: [
				[{ label: "Api URL", key: "url", align: "start", fullsize: true }],
				[{ label: "Status Code", key: "response_code" }],
				[
					{ label: "User", key: "user" },
					{ label: "Time", key: "timestamp" },
				],

				[{ label: null, key: "input_pl", align: "start" }],
				[{ label: null, key: "output_pl", align: "start" }],
			],
			headers: [
				{
					text: "Timestamp",
					value: "timestamp",
					align: "left",
					sortable: false,
				},
				{ text: "User", value: "user", align: "left", sortable: false },
				{ text: "Url", value: "url", align: "left", sortable: false },
				{
					text: "Status Code",
					value: "response_code",
					align: "left",
					sortable: false,
				},
			],
		};
	},
	mixins: [table, permissions],
	computed: {
		...mapGetters({ userList: "userList" }),
		auditExportData() {
			return [
				[
					["user", "User ID"],
					["timestamp", "Timestamp"],
					["url", "API URL"],
					["response_code", "Status Code"],
					["input_pl", "Request"],
					["output_pl", "Response"],
				],
				this.list.map((el) => {
					const input_pl = JSON.stringify(el.input_pl);
					const output_pl = JSON.stringify(el.output_pl);
					return { ...el, input_pl, output_pl };
				}),
			];
		},
		...mapGetters({ workerUrl: "loganUrl" }),
		...mapGetters("apiCall", { authToken: "accessToken" }),
		...mapGetters("audit", {
			list: "list",
			filter: "filter",
			pagination: "pagination",
		}),
		filterIsEmpty() {
			return _.isEqual(this.auditFilter, this.emptyFilter);
		},
	},
	methods: {
		...mapActions({ loadUsers: "getUserList" }),
		async blur() {
			await new Promise((r) => setTimeout(() => r(), 150));
			document.activeElement.blur();
		},
		async copyUserId(e) {
			this.timeout = this.timeout === 1000 ? 1001 : 1000;
			this.copiedText = "User ID";
			this.copied = true;
			navigator.clipboard.writeText(e);
			await new Promise((r) => setTimeout(() => r(), 100));
			this.blur();
		},
		async copy(pl) {
			this.timeout = this.timeout === 1000 ? 1001 : 1000;
			this.copiedText = "Payload";
			this.copied = true;
			navigator.clipboard.writeText(JSON.stringify(pl));
			await new Promise((r) => setTimeout(() => r(), 100));
			this.blur();
		},
		userExist(e) {
			if (!this.userList) {
				return false;
			}
			return this.userList?.data?.data?.findIndex((el) => el._id === e) > -1;
		},

		getUser(id) {
			const tmpUsr = this.userList?.data?.data?.find((el) => el._id === id);
			if (!tmpUsr) return id;
			return `${tmpUsr.first_name} ${tmpUsr.last_name}`;
		},
		getUserLink(e) {
			const tmpUsr = this.userList?.data?.data?.find((el) => el._id === e);
			this.$router.push({
				name: "user-detail",
				params: {
					user_id: tmpUsr._id,
					breadcrumbs: {
						title: `User ${tmpUsr.first_name} ${tmpUsr.last_name}`,
					},
				},
			});
		},
		getUrlPath(url) {
			return decodeURIComponent(new URL(url).pathname);
		},
		async showDetail(item) {
			this.detail = item;
			this.detailDialog = true;
			await this.$nextTick();
			hljs.highlightElement(document.querySelector("#request"));
			hljs.highlightElement(document.querySelector("#response"));
		},
		exportClose(e) {
			this.exportDialog = false;
			this.$refs.auditExport.onClose();
		},
		onExportOpen() {
			this.exportDialog = true;
			setTimeout(() => {
				this.$refs.auditExport.onExportOpen();
			}, 500);
		},
		async textFieldSanitize(content) {
			await this.$nextTick();
			if (content === "" || content === null) {
				this.auditFilter.url_part = undefined;
			}
		},
		async clearFilter() {
			Object.keys(this.emptyFilter).forEach((el) =>
				this.$set(this.auditFilter, el, this.emptyFilter[el])
			);
			await this.$nextTick();
			this.loadList({ payload: this.auditFilter });
		},
		async onFilter(skipCheck = false) {
			this.loading = true;
			await this.$nextTick();
			if (this.auditFilter.url_part === null) {
				this.auditFilter.url_part = undefined;
			}
			if (!skipCheck && _.isEqual(this.auditFilter, this.filter)) {
				this.loading = false;
				return;
			}
			await this.loadList({ payload: this.auditFilter });
			this.setPagination({ ...this.pagination, page: 1 });
			this.loading = false;
		},
		...mapActions("audit", {
			loadList: "loadList",
		}),
		...mapMutations("audit", {
			setPagination: "pagination",
			setFilter: "filter",
		}),
	},
	async updated() {
		await new Promise((r) => setTimeout(r(), 20));
		this.resHeight = this.$refs?.resp?.offsetHeight;
		this.reqHeight = this.$refs?.req?.offsetHeight;
	},
	created() {
		if (
			this._usrFlagsSome({
				key: "management.users.list",
				val: this.permissions.READ,
			})
		) {
			this.loadUsers({ payload: { per_page: 5000, page: 1 } });
		}
		this.$syncAndAssign(this.$route.name, "auditFilter");
		this.setFilter(this.auditFilter);
	},
};
</script>

<style scoped>
::v-deep .v-dialog {
	overflow-y: visible !important;
}
</style>
