var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "Groups", fluid: "", tag: "section" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          on: {
            "click:outside": _vm.exportClose,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.exportClose.apply(null, arguments)
            },
          },
          model: {
            value: _vm.exportDialog,
            callback: function ($$v) {
              _vm.exportDialog = $$v
            },
            expression: "exportDialog",
          },
        },
        [
          _c("ExportDialog", {
            ref: "auditExport",
            attrs: {
              builder: "object",
              delimiter: ";",
              data: _vm.auditExportData,
              filename: "testport",
            },
            on: { close: _vm.exportClose },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "my-2 fakin-dialog",
          attrs: { width: 1200, "max-width": 1200 },
          model: {
            value: _vm.detailDialog,
            callback: function ($$v) {
              _vm.detailDialog = $$v
            },
            expression: "detailDialog",
          },
        },
        [
          _c("KeyValueCard", {
            attrs: {
              item: _vm.detail,
              cols: _vm.rows,
              title: "Audit Detail",
              loading: false,
              color: "secondary",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.url.value`,
                  fn: function ({ item }) {
                    return [
                      _c("code", { staticStyle: { "font-size": "0.9rem" } }, [
                        _vm._v(_vm._s(item.url)),
                      ]),
                    ]
                  },
                },
                {
                  key: `item.timestamp.value`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("Date")(item.timestamp)) + " "
                      ),
                    ]
                  },
                },
                {
                  key: `item.input_pl.value`,
                  fn: function ({ item }) {
                    return [
                      _c("span", { staticClass: "subtitle-2" }, [
                        _vm._v("Request"),
                      ]),
                      _c(
                        "pre",
                        {
                          key: `${item.url}-${item.timestamp}-input`,
                          staticStyle: { position: "relative" },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              style: `position: absolute; top: ${
                                _vm.reqHeight > 45 ? "10px" : "2px"
                              }; right: 10px; z-index: 20`,
                              attrs: { size: "18" },
                              on: {
                                click: function ($event) {
                                  return _vm.copy(item.input_pl)
                                },
                              },
                            },
                            [_vm._v("mdi-content-copy")]
                          ),
                          _c(
                            "code",
                            {
                              ref: "req",
                              style: `max-height: max(calc(25vh + 25vh - ${_vm.resHeight}px) , 25vh)`,
                              attrs: { id: "request" },
                            },
                            [_vm._v(_vm._s(item.input_pl))]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: `item.output_pl.value`,
                  fn: function ({ item }) {
                    return [
                      _c("span", { staticClass: "subtitle-2" }, [
                        _vm._v("Response"),
                      ]),
                      _c(
                        "pre",
                        {
                          key: `${item.url}-${item.timestamp}-output`,
                          staticStyle: { position: "relative" },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              style: `position: absolute; top: ${
                                _vm.resHeight > 45 ? "10px" : "2px"
                              }; right: 10px; z-index: 20`,
                              attrs: { size: "18" },
                              on: {
                                click: function ($event) {
                                  return _vm.copy(item.output_pl)
                                },
                              },
                            },
                            [_vm._v("mdi-content-copy")]
                          ),
                          _c(
                            "code",
                            {
                              ref: "resp",
                              style: `max-height: max(calc(25vh + 25vh - ${_vm.reqHeight}px) , 25vh)`,
                              attrs: { id: "response" },
                            },
                            [_vm._v(_vm._s(item.output_pl))]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: `item.response_code.value`,
                  fn: function ({ item }) {
                    return [
                      _c("span", [_vm._v(_vm._s(item.response_code))]),
                      _c("v-btn", {
                        staticClass: "elevation-0 ml-2",
                        attrs: {
                          color:
                            item.response_code >= 400 ? "error" : "success",
                          width: "18",
                          height: "18",
                          fab: "",
                        },
                      }),
                    ]
                  },
                },
                {
                  key: `item.user.value`,
                  fn: function ({ item }) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.getUser(item.user)))]),
                      _vm.userExist(item.user)
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "ml-2",
                              attrs: { size: "18", color: "primary" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.getUserLink(item.user)
                                },
                              },
                            },
                            [_vm._v(" mdi-open-in-new ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "v-card--material true pa-3 px-5 py-3" },
        [
          _c("CardHeading", {
            attrs: { title: "Audit Log" },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          elevation: "1",
                          fab: "",
                          small: "",
                          color: "primary",
                        },
                        on: { click: _vm.onExportOpen },
                      },
                      [_c("v-icon", [_vm._v("mdi-arrow-down-bold-circle")])],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              "must-sort": "",
              "sort-desc": true,
              headers: _vm.headers,
              items: _vm.list,
              "item-class": _vm.itemClass,
              options: _vm.options,
              "server-items-length": _vm.total_records,
              loading: _vm.loading,
              "footer-props": _vm.footerProps,
            },
            on: {
              "update:options": function ($event) {
                _vm.options = $event
              },
              "click:row": _vm.showDetail,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function ({ pagination }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between",
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "mr-3 col-3 pb-0",
                            attrs: {
                              dense: "",
                              clearable: "",
                              dark: _vm.$vuetify.theme.dark,
                              light: !_vm.$vuetify.theme.dark,
                              "hide-details": "",
                              label: "Search",
                            },
                            on: {
                              "click:clear": _vm.onFilter,
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onFilter.apply(null, arguments)
                              },
                              input: _vm.textFieldSanitize,
                            },
                            model: {
                              value: _vm.auditFilter.url_part,
                              callback: function ($$v) {
                                _vm.$set(_vm.auditFilter, "url_part", $$v)
                              },
                              expression: "auditFilter.url_part",
                            },
                          }),
                          _c(
                            "v-data-footer",
                            _vm._b(
                              {
                                staticClass: "anton",
                                attrs: {
                                  options: _vm.options,
                                  pagination: pagination,
                                },
                                on: {
                                  "update:options": function ($event) {
                                    _vm.options = $event
                                  },
                                },
                              },
                              "v-data-footer",
                              _vm.footerProps,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: `item.timestamp`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("Date")(item.timestamp)) + " "
                      ),
                    ]
                  },
                },
                {
                  key: `item.response_code`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(" " + _vm._s(item.response_code) + " "),
                      _c("v-btn", {
                        staticClass: "elevation-0 ml-2",
                        attrs: {
                          color:
                            item.response_code >= 400 ? "error" : "success",
                          width: "18",
                          height: "18",
                          fab: "",
                        },
                      }),
                    ]
                  },
                },
                {
                  key: `item.url`,
                  fn: function ({ item }) {
                    return [_vm._v(_vm._s(_vm.getUrlPath(item.url)))]
                  },
                },
                {
                  key: `item.user`,
                  fn: function ({ item }) {
                    return [
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ hover }) {
                                return [
                                  _vm.userExist(item.user)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            size: "20",
                                            color: hover
                                              ? "kajot-text"
                                              : "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.getUserLink(item.user)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-open-in-new ")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ hover }) {
                                return [
                                  _vm.userExist(item.user)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            size: "18",
                                            color: hover
                                              ? "kajot-text"
                                              : "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.copyUserId(item.user)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-content-copy ")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" " + _vm._s(_vm.getUser(item.user)) + " "),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "FilterSideBar",
        {
          attrs: {
            refreshButton: "",
            filterIsEmpty: _vm.filterIsEmpty,
            loading: _vm.loading,
            width: "400px",
          },
          on: {
            clear: _vm.clearFilter,
            refresh: function ($event) {
              return _vm.onFilter(true)
            },
          },
        },
        [
          _c("AuditForm", {
            ref: "form",
            attrs: { loading: _vm.loading, filterIsEmpty: _vm.filterIsEmpty },
            on: { apply: _vm.onFilter },
            model: {
              value: _vm.auditFilter,
              callback: function ($$v) {
                _vm.auditFilter = $$v
              },
              expression: "auditFilter",
            },
          }),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticStyle: {
            position: "fixed",
            bottom: "20px",
            "text-align": "center",
          },
          attrs: {
            app: "",
            color: "menu_background",
            transition: "slide-y-reverse-transition",
            timeout: _vm.timeout,
            "content-class": "kajot-text--text",
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { fab: "", text: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          _vm.copied = false
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "kajot-text", small: "" } },
                        [_vm._v(" mdi-close ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.copied,
            callback: function ($$v) {
              _vm.copied = $$v
            },
            expression: "copied",
          },
        },
        [
          _c(
            "div",
            { staticClass: "align-center" },
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information"),
              ]),
              _vm._v(" " + _vm._s(_vm.copiedText) + " copied to clipboard "),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }