<template>
	<v-container class="pt-0 mt-0 px-0">
		<v-form
			ref="form"
			v-model="valid">
			<div class="mt-3 pb-0">
				<v-text-field
					class="mb-6"
					hide-details
					clearable
					@input="textFieldSanitize"
					label="User ID"
					v-model="filter_.user_id"></v-text-field>
				<span>Timestamps</span>
				<v-slide-x-reverse-transition>
					<v-icon
						v-if="filter_.ts_from || filter_.ts_to"
						@click="clearCreated"
						class="primary--text">
						mdi-close
					</v-icon>
				</v-slide-x-reverse-transition>
			</div>
			<v-row class="py-0 my-0">
				<v-col
					class="py-0 my-0"
					cols="12">
					<DatetimePicker
						title="Before"
						:createdNow="false"
						:key="filter_.ts_from"
						v-model="filter_.ts_from"></DatetimePicker>
				</v-col>
				<v-col
					class="py-0 my-0"
					cols="12">
					<DatetimePicker
						title="After"
						:createdNow="false"
						:key="filter_.ts_to"
						v-model="filter_.ts_to"></DatetimePicker>
				</v-col>
			</v-row>
		</v-form>
		<div class="d-flex justify-center mt-2">
			<v-btn
				:disabled="filterIsEmpty || loading || !valid"
				:loading="loading"
				class="primary"
				@click="emitFilter">
				Apply
			</v-btn>
		</div>
	</v-container>
</template>

<script>
import DatetimePicker from "../forms/DatetimePicker.vue";

export default {
	components: { DatetimePicker },
	model: {
		prop: "filter",
		event: "filter",
	},
	watch: {
		filter_: {
			handler(val) {
				this.$emit("filter", val);
			},
			deep: true,
		},
	},
	props: {
		filter: {
			type: Object,
			default: () => {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		filterIsEmpty: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			valid: true,
			filter_: this.filter,
		};
	},
	computed: {},
	methods: {
		clearCreated() {
			this.filter_.ts_from = undefined;
			this.filter_.ts_to = undefined;
			this.$emit("filter", this.filter_);
		},
		async textFieldSanitize(content) {
			await this.$nextTick();
			if (content === "") {
				this.filter_.user_id = undefined;
			}
		},
		async onClear() {
			await this.$nextTick();
			this.filter_.user_id = "";
		},
		clearFilter() {
			this.$emit("filter", {});
		},
		emitFilter() {
			this.$emit("apply");
		},
	},
};
</script>
