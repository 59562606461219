var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-0 mt-0 px-0" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt-3 pb-0" },
            [
              _c("v-text-field", {
                staticClass: "mb-6",
                attrs: { "hide-details": "", clearable: "", label: "User ID" },
                on: { input: _vm.textFieldSanitize },
                model: {
                  value: _vm.filter_.user_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter_, "user_id", $$v)
                  },
                  expression: "filter_.user_id",
                },
              }),
              _c("span", [_vm._v("Timestamps")]),
              _c(
                "v-slide-x-reverse-transition",
                [
                  _vm.filter_.ts_from || _vm.filter_.ts_to
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "primary--text",
                          on: { click: _vm.clearCreated },
                        },
                        [_vm._v(" mdi-close ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "py-0 my-0" },
            [
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "12" } },
                [
                  _c("DatetimePicker", {
                    key: _vm.filter_.ts_from,
                    attrs: { title: "Before", createdNow: false },
                    model: {
                      value: _vm.filter_.ts_from,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter_, "ts_from", $$v)
                      },
                      expression: "filter_.ts_from",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "12" } },
                [
                  _c("DatetimePicker", {
                    key: _vm.filter_.ts_to,
                    attrs: { title: "After", createdNow: false },
                    model: {
                      value: _vm.filter_.ts_to,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter_, "ts_to", $$v)
                      },
                      expression: "filter_.ts_to",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-center mt-2" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary",
              attrs: {
                disabled: _vm.filterIsEmpty || _vm.loading || !_vm.valid,
                loading: _vm.loading,
              },
              on: { click: _vm.emitFilter },
            },
            [_vm._v(" Apply ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }